import { useMutation } from 'react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const createArticle = async (formData: FormData) => {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/articles`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};

export const useSubmitArticle = (setServerErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>) => {
    const navigate = useNavigate(); // используем useNavigate для редиректа

    return useMutation(createArticle, {
        onSuccess: (data) => {
            console.log("Success:", data);
            // Выполняем редирект на страницу редактирования статьи с идентификатором
            if (data && data.article.id) {
                navigate(`/update-article/${data.article.id}`);
            }
        },
        onError: (error: unknown) => {
            if (axios.isAxiosError(error) && error.response) {
                const response = error.response.data;
                if (response.conflicts) {
                    console.error("Conflict fields:", response.conflicts);
                    // Создание объекта ошибок на основе конфликтующих полей
                    const errors: Record<string, string> = {};
                    response.conflicts.forEach((field: string) => {
                        errors[field] = `Поле "${field}" уже существует. Пожалуйста, используйте другое значение.`;
                    });
                    setServerErrors(errors);
                } else {
                    console.error("Error response:", response.message);
                    setServerErrors({ general: response.message });
                }
            } else if (error instanceof Error) {
                console.error("Error message:", error.message);
                setServerErrors({ general: error.message });
            } else {
                console.error("Unknown error:", error);
                setServerErrors({ general: "Неизвестная ошибка" });
            }
        }
    });
};