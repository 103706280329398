import React from 'react';
import { useArticles } from "../../hooks/articles/useArticles";
import Layout from "../Layout/Layout";
import { Link } from "react-router-dom";
import { useUserField } from "hooks/userFields/useUserField";
import { Article } from "../../types/Article";
import { useGetUser } from 'hooks/useGetUser';

const ArticlesList: React.FC = () => {
    const userId = localStorage.getItem('userId') || '';
    const { data: articles, error, isLoading } = useArticles();
    const { data: user, isLoading: loadingUser, error: errorUser } = useGetUser(userId);

    // console.log("articles: " + articles);


    console.log("articles: " + JSON.stringify(articles, null, 2));


    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {(error as Error).message}</div>;
    }

    if (!user || !user.articles) {
        return <div>Нет доступных данных пользователя или статей.</div>;
    }

    return (
        <Layout>
            <div>
                <h2>Список статей</h2>
                <Link to="/create-article">Создать новую статью</Link>
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Img</th>
                            <th>ID</th>
                            <th>Название</th>

                            <th scope="col"></th> {/* Новый столбец для действий */}
                        </tr>
                    </thead>
                    <tbody>
                        {articles.map((article: { id: number; name: string; imageUrl?: string }) => (
                            <tr key={article.id}>
                                <td>
                                    {article.imageUrl ? (
                                        <img
                                            className="allFillingImg"
                                            src={article.imageUrl}
                                            alt={article.name}
                                            style={{ width: '100px', height: 'auto' }} // Дополнительно для корректного отображения
                                        />
                                    ) : (
                                        <div>Изображение отсутствует</div>
                                    )}
                                </td>
                                <td>{article.id}</td>
                                <td>{article.name}</td>
                                <td>
                                    <Link to={`/update-article/${article.id}`}>Редактировать</Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Layout>
    );
};

export default ArticlesList;
