import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { useSubmitArticle } from "../../hooks/articles/useSubmitArticle";
import Layout from "../Layout/Layout";
import { useFormData } from "../../utils/useFormData";
import { Category } from "../../types/Category";
import { useCategories } from "../../hooks/categories/useCategories";
import { FormRow } from "../fragment/FormRow";
import { generateSlug } from "../../utils/generateSlug";
import { Link } from "react-router-dom";
import axios from 'axios';


const CreateArticleForm: React.FC = () => {
    const [serverErrors, setServerErrors] = useState<Record<string, string>>({});
    const { mutate: submitArticle, isLoading, error, isSuccess } = useSubmitArticle(setServerErrors);

    const { createFormData } = useFormData();
    const { data: categories } = useCategories();
    const userId = localStorage.getItem('userId');
    


    const renderCategoryOptions = (categories: Category[], level = 0): React.ReactNode => {
        return categories.map((category) => (
            <React.Fragment key={category.id}>
                <option value={category.id}>
                    {'-'.repeat(level)} {category.categoryName}
                </option>
                {category.children && renderCategoryOptions(category.children, level + 1)}
            </React.Fragment>
        ));
    };

    const onError = (error: unknown) => {
        if (axios.isAxiosError(error) && error.response) {
            const response = error.response.data;
    
            if (response.conflicts) {
                // Обновление состояния ошибок
                const fieldErrors: Record<string, string> = {};
                response.conflicts.forEach((conflict: string) => {
                    fieldErrors[conflict] = `${conflict.charAt(0).toUpperCase() + conflict.slice(1)} уже существует.`;
                });
                setServerErrors(fieldErrors); // Устанавливаем ошибки в состояние
            } else if (response.message) {
                console.error("Server error:", response.message);
            }
        } else if (error instanceof Error) {
            console.error("Error:", error.message);
        }
    };

    return (
        <Layout>
            <div>
                <h1>Создать статью</h1>
                <Link to="/all-articles">Все статьи</Link>
                <Formik
                    initialValues={{
                        name: '',
                        slug: '',
                        title: '',
                        shortDescription: '',
                        detailedDescription: '',
                        metadescription: '',
                        imageUrl: undefined,
                        userId: userId ? parseInt(userId) : null,
                        isActive: true,
                        sortOrder: 0,
                        categories: [] as number[]
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required('Название статьи обязательно'),
                        slug: Yup.string().required('Slug обязателен'),
                        title: Yup.string().required('Мета title обязателен'),
                        shortDescription: Yup.string().required('Короткое описание обязательно'),
                        detailedDescription: Yup.string().required('Детальное описание обязательно'),
                        metadescription: Yup.string(),
                        imageUrl: Yup.mixed(),
                        userId: Yup.number().required('User ID обязателен'),
                        isActive: Yup.boolean(),
                        sortOrder: Yup.number()
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            console.log("Submitting values:", values);
                            const formData = new FormData();
                
                            const articleData = {
                                name: values.name,
                                slug: values.slug,
                                title: values.title,
                                shortDescription: values.shortDescription,
                                detailedDescription: values.detailedDescription,
                                metadescription: values.metadescription,
                                userId: values.userId,
                                isActive: values.isActive,
                                sortOrder: values.sortOrder,
                                categories: values.categories
                            };
                
                            formData.append('articleData', JSON.stringify(articleData));
                            if (values.imageUrl) {
                                formData.append('image', values.imageUrl);
                            }
                
                            await submitArticle(formData); // Отправка данных на сервер
                            setServerErrors({}); // Очистка ошибок после успешной отправки
                        } catch (error: unknown) {
                            onError(error); // Вызов функции onError для обработки ошибок
                        } finally {
                            setSubmitting(false);
                        }
                    }}


                >
                    {({ values, setFieldValue, isSubmitting, handleChange }) => (
                        <Form>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className="col-4">Название статьи</td>
                                        <td className="col-8">
                                            <Field
                                                className="form-control form-control-lg"
                                                type="text"
                                                name="name"
                                                placeholder="Название статьи"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleChange(e);
                                                    const { value } = e.target;
                                                    setFieldValue('name', value);
                                                    setFieldValue('slug', generateSlug(value));
                                                }}
                                            />
                                            <ErrorMessage name="name" component="div" className="error" />
                                            {serverErrors.name && <div className="error">{serverErrors.name}</div>}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="col-4">Slug</td>
                                        <td className="col-8">
                                            <Field
                                                className="form-control form-control-lg"
                                                type="text"
                                                name="slug"
                                                placeholder="Slug"
                                            />
                                            <ErrorMessage name="slug" component="div" className="error" />
                                            {serverErrors.slug && <div className="error">{serverErrors.slug}</div>}
                                        </td>
                                    </tr>



                                    <tr>
                                        <td className="col-4">Категория товара</td>
                                        <td className="col-8">
                                            <Field as="select" name="categories" multiple className="form-control form-control-lg">
                                                <option value="" disabled>Выберите категории</option>
                                                {categories && renderCategoryOptions(categories)}
                                            </Field>
                                        </td>
                                    </tr>

                                    <FormRow label="Мета title" type="text" name="title" placeholder="Мета title" />
                                    <FormRow
                                        label="Короткое описание"
                                        type="textarea"
                                        name="shortDescription"
                                        placeholder="Введите короткое описание"
                                        showHtmlToggle={true}
                                    />
                                    <FormRow
                                        label="Детальное описание"
                                        type="textarea"
                                        name="detailedDescription"
                                        placeholder="Введите детальное описание"
                                        showHtmlToggle={true}
                                    />
                                    <FormRow label="Мета описание" type="text" name="metadescription" placeholder="Мета описание" />
                                    <tr>
                                        <td className="col-4">Изображения</td>
                                        <td className="col-8">
                                            <input
                                                className="form-control form-control-lg"
                                                type="file"
                                                name="imageUrl"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const file = e.currentTarget.files?.[0];
                                                    setFieldValue("imageUrl", file);
                                                }}
                                                multiple
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button type="submit" disabled={isSubmitting}>
                                Создать статью
                            </button>
                        </Form>
                    )}
                </Formik>

            </div>
        </Layout>
    );
};

export default CreateArticleForm;