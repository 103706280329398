import Layout from 'components/Layout/Layout';
import {useFetchAllProducts} from 'hooks/products/useFetchAllProducts';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {storeStore} from "../../stores/StoreStore";
import {observer} from 'mobx-react-lite';
import {store} from "../../store";
import {Product, ProductCategory} from "../../types/ProductTypes";
import "./products.scss";


const ProductsComponent: React.FC = observer(() => {
    const [page, setPage] = useState(0);
    const size = 10;
    const [searchQuery, setSearchQuery] = useState('');
    const { data: productsData, error, isLoading } = useFetchAllProducts(page, size, searchQuery);
    const [allProducts, setAllProducts] = useState<Product[]>([]);

    useEffect(() => {
        if (!storeStore.currentStore) {
            const userId = localStorage.getItem("userId");
            storeStore.fetchStoreById(Number(userId));
        }
    }, []);

    useEffect(() => {
        if (storeStore.stores.length === 0) {
            storeStore.fetchStoreByUserId();
        }
    }, []);

    useEffect(() => {
        if (productsData) {
            setAllProducts(productsData.content);
        }
    }, [productsData]);

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
        setPage(0); // Сбросить на первую страницу при новом поиске
    };

    // if (isLoading) return <div>Loading...</div>;
    if (error) return <div>An error occurred: {error.message}</div>;

    return (
        <Layout>
            <div>
                <h2>Продукты</h2>
                <Link to="/create-product">Создать новый товар</Link>
                <input
                    className="form-control"
                    type="text"
                    placeholder="Поиск продуктов"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Картинка</th>
                        <th scope="col">Название</th>
                        <th scope="col">Категории</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {allProducts.map((product) => (
                        <tr key={product.id}>
                            <td>{product.id}</td>
                            <td><img src={product.image_url} style={{ maxWidth: '50px' }} alt="Product" /></td>
                            <td>{product.productname}</td>
                            <td>{product.productCategories.map(pc => pc.category.categoryName).join(', ')}</td>
                            <td><Link to={`/update-product/${product.id}`}>Редактировать</Link></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div>
                    <button onClick={() => handlePageChange(page - 1)} disabled={page === 0}>Previous</button>
                    {[...Array(productsData?.totalPages)].map((_, i) => (
                        <button key={i} onClick={() => handlePageChange(i)} disabled={i === page}>{i + 1}</button>
                    ))}
                    <button onClick={() => handlePageChange(page + 1)} disabled={page >= (productsData?.totalPages || 1) - 1}>Next</button>
                </div>
            </div>
        </Layout>
    );
});

export default ProductsComponent;